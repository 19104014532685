'use strict';

const Event = require('./../lib/events');

class Slider {

    constructor() {

        this.mediaSlider();
        this.logoSlider();

    }

    mediaSlider() {

        let mediaSlider = document.querySelectorAll('.ce-rkmediaslider_media_slider');

        if (mediaSlider.length > 0) {

            // Documentation: https://idangero.us/swiper/api
            const Swiper = require('./../../../../build/node_modules/@components/swiper/dist/js/swiper.min.js');

            for (let slider of mediaSlider) {

                new Swiper(slider.querySelector('.swiper-container'), {
                    navigation: {
                        nextEl: slider.querySelector('.swiper-button-next'),
                        prevEl: slider.querySelector('.swiper-button-prev')
                    },
                    pagination: {
                        el: slider.querySelector('.swiper-pagination'),
                        type: 'fraction'
                    },
                    speed: 600,
                    watchOverflow: true
                });

            }

        }

    }

    logoSlider() {

        let logoSlider = document.querySelectorAll('.ce-eficontentelements_logo_slider');

        if (logoSlider.length > 0) {

            const Swiper = require('./../../../../build/node_modules/@components/swiper/dist/js/swiper.min.js');

            for (let slider of logoSlider) {

                new Swiper(slider.querySelector('.swiper-container'), {
                    navigation: {
                        nextEl: slider.querySelector('.swiper-button-next'),
                        prevEl: slider.querySelector('.swiper-button-prev')
                    },
                    pagination: {
                        el: slider.querySelector('.swiper-pagination'),
                        type: 'fraction'
                    },
                    autoplay: {
                        delay: 5000,
                    },
                    slidesPerView: 5,
                    spaceBetween: 16,
                    speed: 600,
                    watchOverflow: true,
                    breakpoints: {
                        1024: {
                            slidesPerView: 2
                        },
                        767: {
                            slidesPerView: 1
                        }
                    }
                });

            }

        }

    }

}

module.exports = Slider;
