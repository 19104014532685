'use strict';

const $ = require('jquery');
const datatables = require( './../../../../build/node_modules/@components/datatables.net/js/jquery.dataTables.js' );
const datatablesdt = require('./../../../../build/node_modules/@components/datatables.net-dt/js/dataTables.dataTables.js');

class labTable {

    constructor() {

        $('#lab-table').DataTable( {
            "lengthChange": false,
            "info": false,
            "language": {
                "search": "",
                "paginate": {
                    "first":      "<span class=\"show-for-sr\">First</span>",
                    "last":       "<span class=\"show-for-sr\">Last</span>",
                    "next":       "<span class=\"show-for-sr\">Next</span>",
                    "previous":   "<span class=\"show-for-sr\">Previous</span>"
                }
            }
        });

    }

}

module.exports = labTable;