'use strict';

const $ = require('jquery');

class Payment {

    constructor() {

        let $element = $('.ce-payment-form table input[type="checkbox"]');

        function calculatePaymentFee() {

            let checkedElement = $element.filter(':checked');
            let totalFee = 0;

            checkedElement.each(function() {
                totalFee += parseInt($(this).val());
            });

            $('.ce-payment-form .fee').text(totalFee);

            // Hide payment form if there is no fee selected
            if (totalFee === 0) {
                $('.cell.large-5 .callout.small').removeClass('show');
            } else {
                $('.cell.large-5 .callout.small').addClass('show');
            }

        }

        // Calculate the payment fee on pageload
        if ($element.length > 0) {
            calculatePaymentFee();
        }

        // Calculate the payment fee when something changes
        $element.on('change', function () {
            calculatePaymentFee();
        });

    }

}

module.exports = Payment;
