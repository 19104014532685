'use strict';

class Header {

    constructor() {

        let headerHeight = $('.header').outerHeight();

        let headerOffset = 40;
        let lastScrollTop = 0;
        let scrollUpStop = headerHeight + headerOffset;

        App.Globals.$window.on('load scroll touchmove', function () {

            let windowTop = $(this).scrollTop();

            if (windowTop >= (headerHeight + headerOffset)) {

                App.Globals.$body.addClass('hide-header');

                if (windowTop >= lastScrollTop) {

                    if (windowTop >= scrollUpStop) {

                        //  Scroll down
                        App.Globals.$body.addClass('hide-header');

                    }

                } else {

                    //  Scroll up
                    App.Globals.$body.removeClass('hide-header');

                    //  Set last known scroll up. Needed to calculate the new offset for hiding the header when scrolling down
                    scrollUpStop = windowTop;

                }

                lastScrollTop = windowTop;

            } else {

                App.Globals.$body.removeClass('hide-header');

            }

        }).on('load resize', function () {
            if (App.Globals.$window.width() < 1024) {

                $('.toggle-dropdown').on('click', function (event) {
                    event.preventDefault();

                    $(this).closest('.has-children').toggleClass('dropdown-active');
                })

            } else {

                $('.has-children').removeClass('dropdown-active');
                App.Globals.$body.removeClass('menu-open');
            }
        });

    }

}

module.exports = Header;