'use strict';

class InViewportWatcher {

    constructor() {

        //  Initiate Observer for each block element animation
        this.initObserver(
            'block',
            '.block, .keyvisual',
            {
                root: null,
                rootMargin: '200px',
                threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
            },
            this.animateBlocks
        );

    }

    /**
     * Initiate IntersectionObserver
     * @param name
     * @param selector
     * @param options
     * @param callback
     */
    initObserver(name, selector, options, callback) {

        let observers = [];

        let elements = document.querySelectorAll(selector);

        if (elements.length > 0) {

            for (let i = 0; i < elements.length; i++) {

                let boxID = `${name}-${i + 1}`;
                elements[i].id = boxID;

                observers[i] = new IntersectionObserver(callback, options);
                observers[i].observe(document.querySelector('#' + boxID));

            }

        }

    }

    /**
     * Callback for animated blocks
     * @param entries
     */
    animateBlocks(entries) {

        let className = 'animate';

        entries.forEach((entry) => {
            let visiblePercentage = Math.floor(entry.intersectionRatio * 100);
            let block = entry.target;

            if (visiblePercentage > 25) {

                block.classList.add(className);

            }

        });

    }

}

module.exports = InViewportWatcher;
