'use strict';

const $ = require('jquery');

class Form {

    constructor() {

        // Documentation: https://jqueryvalidation.org/validate/
        require('./../../../../build/node_modules/@components/jquery-validation/dist/jquery.validate.min.js');
        // require('./../../../../build/node_modules/@components/jquery-validation/dist/localization/messages_nl.min.js');


        $('.form').each(function() {
            $(this).validate({
                errorPlacement: function(error, element) {
                    error.appendTo(element.closest('fieldset'));
                },
                highlight: function(element) {
                    $(element).closest('fieldset').addClass('error').removeClass('success');
                },
                unhighlight: function(element) {
                    $(element).closest('fieldset').removeClass('error').addClass('success');
                },
                errorElement: 'span',
                errorClass: 'error-message'
            });
        });

        // Documentation: https://jqueryui.com/datepicker
        require('./../../../../build/node_modules/@components/jquery-ui/ui/widgets/datepicker.js');

        $('.datepicker-wrapper input').datepicker({ dateFormat: 'd MM yy' }).val();

        $('.password-wrapper .toggle').on('click', function () {

            let $element = $(this);
            let activeState = 'active';

           $element.parent().toggleClass(activeState);

           if ($element.parent().hasClass(activeState)) {

               $element.next().attr('type', 'text');

           } else {

               $element.next().attr('type', 'password');
           }

        });

        $('.custom-file-upload').change(function() {
            let stringReplace = this.value.match(/([^\/\\]+)$/);
            let fileName = stringReplace[1];
            $(this)
                .closest('.custom-file-upload-wrapper')
                .find('.file-name').html(fileName);

        });

    }

}

module.exports = Form;
