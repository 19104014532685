'use strict';

class PageTransitions {

    constructor() {

        //  Documentation: https://github.com/defunkt/jquery-pjax
        require('./../../../../build/node_modules/@components/jquery-pjax/jquery.pjax.js');

        App.Globals.$document
            .pjax('a:not([target="_blank"]):not([href^="#"])', '#main')
            .on('pjax:send', function () {
                App.Globals.$body.addClass('page-transition');
            })
            .on('pjax:success', function () {
                App.Globals.$body.removeClass('page-transition');
            });

    }

}

module.exports = PageTransitions;
