'use strict';

const $ = require('jquery');

class Filter {

    constructor() {

        // Scroll to top of the content if there is a filter selected
        $('.ce-eficontentelements_events .category-filter select, .ce-eficontentelements_news .category-filter select').on('change', function () {

            let filterUrl = $(this).val();

            if (filterUrl) {
                window.location = filterUrl;
            }

            $('body').addClass('page-transition');

            return false;

        });

        // Scroll to the content when clicked on pagination
        if ($('.pagination').length > 0) {

            let contentElementID = '';

            if ($('.ce-eficontentelements_events .pagination').length > 0) {
                contentElementID = '#c24';
            } else if ($('.ce-eficontentelements_news .pagination').length > 0) {
                contentElementID = '#c27';
            } else if ($('.ce-search-results .pagination').length > 0) {
                contentElementID = '#c172';
            }

            if (contentElementID !== '') {
                $('.pagination a').each(function() {
                    this.href += contentElementID;
                });
            }

        }

    }

}

module.exports = Filter;
