/**
 * Add components by event and in your desired order
 * @type {[*]}
 */
let components = [
    ['ready', [
        require('./../components/page-transitions')
    ]],
    ['ready pjax:end', [
        require('./../components/cookie-notice'),
        require('./../components/filter'),
        require('./../components/form'),
        require('./../components/foundation'),
        require('./../components/header'),
        require('./../components/lab-table'),
        require('./../components/payment'),
        require('./../components/scroller'),
        require('./../components/slider'),
        require('./../components/trigger'),
        require('./../components/waypoints'),
    ]],
];

module.exports = components;
